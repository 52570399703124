<template>
  <div class="operations_report">
      <content-block v-for="i in reportList" :key="i.group" :title="i.group">
        <div class="operations_report_list">
          <div class="operations_report_entry" v-for="n in i.reports" :key="n.name" @click="handleClick(n.type)">
            <img :src="n.icon" alt=""/>
            <span>{{n.name}}</span>
          </div>
        </div>
      </content-block>
  </div>
</template>
<script>
export default {
  name: 'operations_report',
  data () {
    return {
      reportList: [
        {
          group: '工单报表',
          reports: [
            {
              name: '工单时效统计',
              icon: require('@/assets/imgs/reports/工单时效统计.png'),
              type: 'TIMECONSUME',
            },
            {
              name: '工单作业量统计',
              icon: require('@/assets/imgs/reports/工单作业量统计.png'),
              type: 'ORDERCOUNT',
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleClick (type) {
      this.$router.push({
        name: 'OPERATION_REPORT_DETAIL',
        query: {type},
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  .operations_report {
    height: 100%;
    width: 100%;
    .operations_report_list {
      display: grid;
      grid-template-columns: repeat(auto-fill, 200px);
      grid-column-gap: 24px;
    }

    .operations_report_entry{
      font-size: 16px;
      font-weight: 400;
      color: rgba(36,40,50,0.8000);
      line-height: 1;
      width: 200px;
      height: 68px;
      border-radius: 4px;
      border: 1px solid rgba(113,121,141,0.0800);
      display: flex;
      flex-direction: row;
      align-items: center;

      // &+.operations_report_entry{
      //   margin-right: 24px;
      // }

      &:hover {
        box-shadow: 0px 4px 6px 0px rgba(36,40,50,0.0800);
        cursor: pointer;
      }
      img{
        width: 32px;
        height: 32px;
        padding: 18px 14px;
      }
    }
  }
</style>
